import { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "./Button";
import ScheduleCallModal from "./ScheduleCallModal";
import { useRouter } from "next/router";
import { GlobalContext } from "./context/Global";
import { BANNER_PAGES } from "@/utils/helpers";
import Link from "next/link";
import Image from "next/image";

declare global {
  interface Window {
    HubSpotConversations: any;
  }
}

interface BannerProps {
  page?: string;
  className?: string;
  scrollPos: number;
  setExpand?: any;
  expand: any;
}

const Banner = ({
  page = "contact",
  className = "",
  scrollPos,
  setExpand,
  expand
}: BannerProps) => {
  const router = useRouter();
  const [modal, setModal] = useState(false);
  const { bannerText } = useContext<any>(GlobalContext);




  const filterBannerText = () => {
    let filterPageName: { url: string; Pages: string } | undefined = {
      url: "",
      Pages: "",
    };

    if (router.pathname === "/") filterPageName = BANNER_PAGES[0];
    else
      filterPageName = BANNER_PAGES.slice(1).find((item) =>
        router.pathname.includes(item.url)
      );

    const bannerContent = bannerText?.find(
      (item: any) => item?.attributes?.Pages === filterPageName?.Pages
    );

    return (
      bannerContent ||
      bannerText?.find((item: any) => item?.attributes?.Pages === "Default")
    );
  };

  const handleChatButtonClick = (e: any) => {
    e.preventDefault();
    router.push("/contact-us");
  };

  return (
    <>
      <div
        className={
          expand === false ? `banner ${className} banner-expand` : `banner ${className}`
        }
      >
        <Container>
          <Row className="align-items-center banner-justify-between">
            <Col md={6} lg={6}>
              {page === "contact" ? (
                <h5 className="mb-3 mb-md-0">
                  {filterBannerText()?.attributes?.Content ||
                    ((router.pathname === "/" && !(filterBannerText()?.attributes?.Content)) ? "" : `Transform your ideas into functional applications.`)}
                </h5>
              ) : (
                <h5 className="mb-3 mb-md-0">Join Us & Launch your career</h5>
              )}
            </Col>
            <Col md={5} lg={page === "contact" ? 4 : 2} className="ms-1">
              {page === "contact" ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    type="button"
                    text="Get Started"
                    className="me-1"
                    onClick={handleChatButtonClick}
                  />
                  <Button
                    type="button"
                    text="Schedule a Call"
                    className="ms-1"
                    onClick={() => setModal(true)}
                  />

                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    type="button"
                    text="View Opportunities"
                    className="btn-black"
                    onClick={(e: any) => {
                      if (e.ctrlKey)
                        window.open("/cubet-career/job-board", '_blank')
                      else
                        router.push("/cubet-career/job-board")
                    }}
                  />

                </div>
              )}

            </Col>
            <Col md={2} lg={page === "contact" ? 2 : 2}>
              <div className="ps-4 cursor-pointer float-right d-none d-md-block footer-banner-right-icon" onClick={(e) => {
                e.preventDefault();
                setExpand(true);
              }}>
                <Image
                  src="/assets/icons/chevron-double-left.svg"
                  width={16}
                  height={16}
                  priority={false}
                  alt="Expand"
                  style={{ objectFit: "cover" }}
                  className="img-fluid rotate-180"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* :''} */}
      <div
        className={expand === false ? 'invisible' :
          ((expand === null) && scrollPos < 701) ? "invisible" : "banner-small visible"
        }
      >
        <Link prefetch={false}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setExpand(false);
          }}
        >
          <Image
            src="/assets/icons/chevron-double-left.svg"
            width={16}
            height={16}
            priority={false}
            alt="Expand"
            style={{ objectFit: "cover" }}
            className="img-fluid"
          />
        </Link>
        <Link prefetch={false} href="#" onClick={(e) => {
          e.preventDefault();
          setModal(true);
        }}>
          <Image
            src="/assets/icons/calendar-1.svg"
            width={20}
            height={20}
            priority={false}
            alt="Chat"
            style={{ objectFit: "cover" }}
            className="img-fluid"
          />
        </Link>
        <Link prefetch={false}
          href="#"
          onClick={handleChatButtonClick}
        >
          <Image
            src="/assets/icons/phone-voice.svg"
            width={20}
            height={20}
            priority={false}
            alt="Chat"
            style={{ objectFit: "cover" }}
            className="img-fluid"
          />
        </Link>
      </div>

      {modal && <ScheduleCallModal visible={modal} setVisible={setModal} />}
    </>
  );
};

export default Banner;
