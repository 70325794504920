interface ButtonProps {
  type: any;
  text: string;
  className?: string;
  onClick?: any;
}

const Button = ({ type, text, className = '', onClick }: ButtonProps) => {
  return (
    <button
      type={type}
      className={`form-button ${className}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
